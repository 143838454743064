<template>
    <div class="flex flex-col min-h-16 w-full items-center justify-center" v-if="loadingStates && loadingStates.dateType">
        <Loader />
    </div>
    <div v-else class="flex flex-col w-full">
        <div class="flex space-x-12 border-b justify-between items-center px-2">
            <div class="flex space-x-3">
                <div v-for="tab in tabs" :key="tab.key" class="border-b-4 border-transparent text-gray-400 font-semibold text-md py-1 px-1 cursor-pointer transition-all duration-300" :class="{ 'border-solid border-primary text-gray-700': tab.key === activeTab }" @click="handleActiveTab(tab)">{{ tab.title }}</div>
            </div>
            <!-- <FormulateInput label="Allow Overlapping of Dates" type="checkbox" class="text-sm font-normal" :element-class="(context, classes) => ['pr-2 text-lg'].concat(classes)" v-model="currentBlockField.overlap_allowed" /> -->
            <div class="flex gap-2 items-center">
                <input type="checkbox" class="checkbox checkbox-sm checkbox-primary" v-model="copyCurrentBlockField.overlap_allowed" id="allow-overlapping" :disabled="this.$route.params.action === 'view'"/>
                <label for="allow-overlapping">Allow Overlapping of Dates</label>
            </div>
        </div>
        <div class="body px-2">
            <div class="py-2 flex justify-between space-x-6 border-b">
                <div class="py-2 flex text-sm justify-between items-center space-x-2">
                    <label for="" class="font-semibold" :class="{'text-gray-400' : this.$route.params.action === 'view'}">Valid period (days)</label>
                    <input min="0" v-model="copyCurrentBlockField.valid_gap" @input="onchangevalidGap($event)" @keypress="isNumber" class="border border-gray-400 rounded-lg px-2 w-16" type="number" :disabled="this.$route.params.action === 'view'" :class="{'cursor-not-allowed' : this.$route.params.action === 'view'}"/>
                </div>
                <div class="py-2 flex text-sm justify-between items-center space-x-2" :class="{ 'text-gray-400': activeTab === 'relative' }">
                    <label for="" class="font-semibold" :class="{'text-gray-400' : this.$route.params.action === 'view'}">History (months)</label>
                    <input min="0" v-model="copyCurrentBlockField.history" @input="onchangeHistory($event)" @keypress="isNumber" :disabled="activeTab === 'relative' || this.$route.params.action === 'view'" class="border border-gray-400 rounded-lg px-2 w-16" type="number" :class="{'cursor-not-allowed' : this.$route.params.action === 'view'}"/>
                </div>
            </div>

            <div class="flex space-y-2 flex-col py-4 text-gray-400">
                <h2 class="font-semibold" :class="{ 'disabledField' : this.$route.params.action === 'view' , 'text-gray-700': activeTab === 'relative'}">Start Date Option</h2>
                <div class="flex space-x-6 justify-between overflow-hidden">
                    <div class="w-1/2 flex flex-col space-y-1">
                        <p class="text-sm font-semibold text-gray-400">From Date</p>
                        <Dropdown
                            reposition
                            searchable
                            placeholder="From date"
                            :options="dateTypeFields"
                            :colors="{
                                text: '#282828',
                                bg: 'white',
                                svg: '#282828',
                                border: '#bcbcbc',
                            }"
                            :value="copyCurrentBlockField.start_date_field"
                            @input="(input) => updateOption(input, 'start_date_field')"
                            :config="{ label: 'label', trackBy: 'id' }"
                            :limit="1"
                            minWidth="100%"
                            maxWidth="100%"
                            width="102px"
                            :disabled="activeTab === 'absolute' || this.$route.params.action === 'view' "
                        />
                    </div>
                    <div class="w-1/2 flex flex-col space-y-1" :class="{ 'disabledField' : this.$route.params.action === 'view', 'text-gray-700': activeTab === 'relative' }">
                        <p class="text-sm font-semibold text-current">Start Date Label</p>
                        <FormulateInput v-model="copyCurrentBlockField.start_date_label" :disabled="activeTab === 'absolute' || this.$route.params.action === 'view'" placeholder="Start Date Label" type="text" outer-class="mb-4 w-full" input-class="border rounded-xl input-bordered rounded h-9  px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" class="text-sm font-normal" @input="(input) => updateLabel(input,'start_date_label')" />
                    </div>
                </div>

                <div class="pt-4 text-gray-400">
                    <h2 class="font-semibold" :class="{'disabledField' : this.$route.params.action === 'view', 'text-gray-700': activeTab === 'relative' }">End Date Option</h2>
                    <div class="flex space-x-6 h-10 mt-1" :class="{'disabledField' : this.$route.params.action === 'view', 'text-gray-700': activeTab === 'relative' }">
                        <FormulateInput 
                            :disabled="activeTab === 'absolute' || this.$route.params.action === 'view'" 
                            :options="{ till_present: 'Till Present', till_date: 'Till Date' }" 
                            type="radio" 
                            class="text-sm font-normal" 
                            :element-class="(context, classes) => [' text-lg flex gap-4'].concat(classes)" 
                            @input="(input) => updateTillPreset(input)" 
                            :value="copyCurrentBlockField.till_present ? 'till_present' : 'till_date'" />
                    </div>
                    <div class="flex space-x-6 justify-between overflow-hidden">
                        <div class="w-1/2 flex flex-col space-y-1">
                            <p class="text-sm font-semibold text-gray-400">From Date</p>
                            <Dropdown
                                reposition
                                searchable
                                placeholder="From date"
                                :options="dateTypeFields"
                                :colors="{
                                    text: '#282828',
                                    bg: 'white',
                                    svg: '#282828',
                                    border: '#bcbcbc',
                                }"
                                :value="copyCurrentBlockField.end_date_field"
                                @input="(input) => updateOption(input, 'end_date_field')"
                                :config="{ label: 'label', trackBy: 'id' }"
                                :limit="1"
                                minWidth="100%"
                                maxWidth="100%"
                                width="102px"
                                :disabled="activeTab === 'absolute' || disableinputField || this.$route.params.action === 'view'"
                            />
                        </div>
                        <div class="w-1/2 flex flex-col space-y-1" :class="{'disabledField' : this.$route.params.action === 'view', 'text-gray-700': activeTab === 'relative' }">
                            <p class="text-sm font-semibold text-current">End Date Label</p>
                            <FormulateInput v-model="copyCurrentBlockField.end_date_label" :disabled="activeTab === 'absolute' || disableinputField || this.$route.params.action === 'view'" type="text" placeholder="End Date Label" outer-class="mb-4 w-full" input-class="border rounded-xl input-bordered rounded h-9  px-3 py-2 leading-none focus:border-primary-focus outline-none input border-box w-full mb-1" label-class="font-medium text-sm" class="text-sm font-normal" @input="(input) => updateLabel(input,'end_date_label')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-end">
            <Button :disabled="isSaveDisable" class="disabled:opacity-50" text="Save" type="primary" @click="update"         :style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' , display: this.$route.params.action !== 'view' ? 'block' : 'none'}"/>
        </div>
    </div>
</template>

<script>
import { isNumber } from "@/plugins/functions";
import Dropdown from "@shared/components/dropdown-base";
import Button from "@shared/components/button";
import Loader from "@shared/loader"

export default {
    name: "gap-timeline",
    props: {
        currentBlockField: {
            type: Object,
            default: () => {},
        },
        dateTypeFields: {
            type: Array,
            default: () => [],
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        Dropdown,
        Button,
        Loader
    },
    data() {
        return {
            tabs: [
                {
                    key: "absolute",
                    title: "Absolute",
                },
                {
                    key: "relative",
                    title: "Relative",
                },
            ],
            selectedTab: "absolute",
            // currentBlockField: {
            //     relative: false,
            //     valid_gap: null,
            //     history: null,
            //     overlap_allowed: false,
            //     start_date_field: null,
            //     start_date_label: "",
            //     end_date_field: null,
            //     end_date_label: "",
            //     till_present: false,
            // },
            copyCurrentBlockField: {},
            tillPresentDate: ""
        };
    },
        watch:{
            getCurrentBlockData() {
                this.getDataofCurrentBlock();
    },
    },
    mounted(){
        let relative_status = this.activeTab == 'relative' ? true : false;
        this.copyCurrentBlockField = {...this.currentBlockField, relative: relative_status}
    },
    computed: {
        getCurrentBlockData(){
            return this.currentBlockField;
        },
        activeTab() {
            return this.currentBlockField.relative ? "relative" : "absolute";
        },
        // getBlockDetails: {
        //     get() {
        //         return this.currentBlockField;
        //     },
        //     set(val) {
        //         this.currentBlockField = val;
        //     },
        // },
        disableinputField() {
            if(this.activeTab == 'relative' && this.tillPresentDate === "till_present") {
                return true
            }
            return false
        },
        isSaveDisable() {
            let { relative, valid_gap, history, start_date_field, end_date_field, till_present } = this.currentBlockField;
            valid_gap = parseInt(valid_gap)
            history = parseInt(history)
            if (relative && till_present) {
                return isNaN(valid_gap) || valid_gap < 0 || !start_date_field || false;
            } else if (relative) {
                return (isNaN(valid_gap) || valid_gap < 0 ) || !start_date_field || !end_date_field || false;
            } else {
                return isNaN(history) || history < 0 || isNaN(valid_gap) || valid_gap < 0  || false;
            }
        },
    },
    methods: {
        isNumber,
        getDataofCurrentBlock(){
            this.copyCurrentBlockField = {...this.currentBlockField}
        },
        handleActiveTab(tab) {
            // this.$emit("handleResetGapTimelineFields");
            let relative = !this.currentBlockField.relative
            this.copyCurrentBlockField = {...this.currentBlockField, relative}
            this.$emit("updateActiveTab", tab.key);
            // this.selectedTab = tab.key;
        },
        updateOption(option, key) {
            this.$emit("updateOption", { option, key });
        },
        updateTillPreset(input) {
            let startDateLabel = this.copyCurrentBlockField.start_date_label;
            this.tillPresentDate = input;
            this.$emit("updateTillPreset", {input, startDateLabel});
        },
        updateLabel(input,key){
            this.$emit("updateLabel", { input, key });
        },
        update() {
            if(this.copyCurrentBlockField.till_present == null){
                this.copyCurrentBlockField.till_present = false;
            }
            this.$emit("handleUpdateBlockFieldGapTimeLine",this.copyCurrentBlockField);
        },
        onchangeHistory(e) {
            this.currentBlockField.history = e.target.value
        },
        onchangevalidGap(e) {
            this.currentBlockField.valid_gap = e.target.value;
        }
    },
};
</script>

<style scoped>
.formulate-input-element--radio {
    @apply pr-2;
}
.disabledField {
    color: #C0C5CC !important;
}
</style>
